<template lang="pug">
FcwModal(
  v-if="!isSmAndDown"
  v-model="isVisible"
  :modal-class="classes"
  width="auto"
  raw
  inline
)
  template(#default="{ close }")
    .FcwMobileAppStoresModal__content
      .FcwMobileAppStoresModal__contentLeft(v-if="showSharing")
        .FcwMobileAppStoresModal__text
          .FcwMobileAppStoresModal__titleFeatures
            h4 {{ t('common__sharing') }}
            FcwChecklist(:items="sharingAppFeatures")
          FcwMobileAppStoreButtons(
            mode="static"
            :service-type="ServiceType.Sharing"
          )
        FImage(
          v-if="sharingAppDeviceMockup.src && (!showLeasing || leasingAppDeviceMockup.src)"
          v-bind="sharingAppDeviceMockup"
          width="200"
          height="264"
          fit="contain"
          loading="eager"
        )
      .FcwMobileAppStoresModal__contentRight(v-if="showLeasing")
        FImage(
          v-if="leasingAppDeviceMockup.src && (!showSharing || sharingAppDeviceMockup.src)"
          v-bind="leasingAppDeviceMockup"
          width="200"
          height="264"
          fit="contain"
          loading="eager"
        )
        .FcwMobileAppStoresModal__text
          .FcwMobileAppStoresModal__titleFeatures
            h4 {{ t('common__leasing') }}
            FcwChecklist(:items="leasingAppFeatures")
          FcwMobileAppStoreButtons(
            mode="static"
            :service-type="ServiceType.Leasing"
          )
      ClientOnly
        FButton.FcwMobileAppStoresModal__closeButton(
          icon
          ghost
          @click="close"
        )
          FIcon(
            name="crossCircle"
            size="24"
          )
</template>

<style lang="stylus">
.FcwMobileAppStoresModal__content
  display flex
  padding rem(40)

  +media-down('md')
    flex-direction column
    gap rem(40)
    overflow auto

  .FcwChecklist
    gap rem(8)

.FcwMobileAppStoresModal__contentLeft,
.FcwMobileAppStoresModal__contentRight
  display flex
  gap rem(32)
  justify-content space-between

  .FImage
    flex-shrink 0
    align-self center

.FcwMobileAppStoresModal--twoServices
  .FcwMobileAppStoresModal__contentLeft
    > .FImage
      margin-right rem(-40)
      translate 0 rem(-24)

    +media-down('md')
      > .FImage
        margin-right 0
        translate 0 0

    > :first-child
      &.FcwMobileAppStoresModal__text
        margin-right rem(16)

  .FcwMobileAppStoresModal__contentRight
    > .FImage
      margin-left rem(-40)
      translate 0 rem(24)

    +media-down('md')
      > .FImage
        margin-left 0
        translate 0 0

    > :first-child
      &.FcwMobileAppStoresModal__text
        margin-left rem(16)

.FcwMobileAppStoresModal__text
  display flex
  flex-direction column
  justify-content space-between
  gap rem(48)
  min-width rem(276)

.FcwMobileAppStoresModal__titleFeatures
  display flex
  flex-direction column
  gap rem(16)

.FcwMobileAppStoresModal__closeButton
  position absolute
  top rem(12)
  right rem(12)
  z-index 1
</style>

<script setup lang="ts">
import { ServiceType } from '@/models/enums';

import type { FImageProps } from '@fifteen/design-system-vue';

interface FcwMobileAppStoresModalProps {
  /**
   * Whether the modal is visible
   */
  modelValue: boolean;
  /**
   * Sharing app mobile features list
   */
  sharingAppFeatures?: string[];
  /**
   * Sharing app device mockup image
   */
  sharingAppDeviceMockup?: FImageProps;
  /**
   * Leasing app mobile features list
   */
  leasingAppFeatures?: string[];
  /**
   * Sharing app mobile device mockup image
   */
  leasingAppDeviceMockup?: FImageProps;
}

const props = withDefaults(defineProps<FcwMobileAppStoresModalProps>(), {
  sharingAppFeatures: () => [],
  sharingAppDeviceMockup: () => ({ src: '', alt: '' }),
  leasingAppFeatures: () => [],
  leasingAppDeviceMockup: () => ({ src: '', alt: '' }),
});

const { isSmAndDown } = useFBreakpoints();

const isVisible = useVModelProxy({ props });
const { t } = useClientI18n();
const classes = computed<VueClasses>(() => ({
  FcwMobileAppStoresModal: true,
  'FcwMobileAppStoresModal--twoServices':
    hasSharingStaticLinks.value && hasLeasingStaticLinks.value,
}));

const { hasStaticLinks: hasSharingStaticLinks } = useAppStoreLinks(
  ServiceType.Sharing
);
const { hasStaticLinks: hasLeasingStaticLinks } = useAppStoreLinks(
  ServiceType.Leasing
);

const showSharing = computed(() => hasSharingStaticLinks.value);
const showLeasing = computed(() => hasLeasingStaticLinks.value);
</script>
